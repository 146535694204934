import { Grid, GridColumn, Text, ThemeOverride, ThemeProvider } from "@modernatx/ui-kit-react";
import { Global } from "theme-ui";

import { RenderMetadata } from "@/components/RenderMetadata";
import { RenderContent } from "@/components/rsv/RenderContent";
import { ExperienceProvider } from "@/context/ExperienceContext";
import { ExitModalProvider } from "@/hooks/useExitModal";
import { HccTheme, HcpTheme } from "@/profiles/themes";
import { BlockPageProps } from "@/types/Block";
import { Experience } from "@/utils/experience";

interface MresviaPageProps {
  content: BlockPageProps | null;
  experience: Experience | null;
}

export const MresviaPage: React.FC<MresviaPageProps> = ({ content, experience }) => {
  const { country } = experience || {};

  const background = (
    <Global
      styles={{
        html: {
          backgroundColor: "white"
        }
      }}
    />
  );

  if (!country || !content) {
    return (
      <ThemeProvider>
        {background}
        <Grid>
          <GridColumn
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100vh",
              justifyContent: "center"
            }}
          >
            <Text size="lg" as="p">
              The site is not available in your country.
            </Text>
          </GridColumn>
        </Grid>
      </ThemeProvider>
    );
  }
  const experienceData = experience ?? { country: "us" };
  return (
    <ExperienceProvider experience={experienceData}>
      <ThemeProvider theme={content.theme === "hcc" ? HccTheme : HcpTheme}>
        <ThemeOverride mode={content.themeMode}>
          <Global
            styles={{
              html: {
                backgroundColor: content.backgroundColor as string
              }
            }}
          />
        </ThemeOverride>
        <ExitModalProvider exitModal={content.exitModal}>
          <RenderMetadata {...content.metadata} renderGoogleRecaptcha={true} />
          <RenderContent content={content} />
        </ExitModalProvider>
      </ThemeProvider>
    </ExperienceProvider>
  );
};
